import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DienstleistungenKlartextPage = () => (
  <Layout>
    <SEO title="DienstleistungenKlartext" />

    <h1 id="ueberschrift">Serviceangebot &emsp; Stand:&nbsp;{new Date().getFullYear()}</h1>
    
    <p className="note">!!!DIESER BEREICH BEFINDET SICH IM WIEDERAUFBAU!!!</p>

    <p style={{ fontSize: "1.2em", lineHeight: "1.7" }}>
          Kontakt:&ensp;
           <a href="tel:+49-9187-7081220" title="anrufen" style={{ fontSize: "1em"  }}>+49 (0) 91 87 / 70 81 220</a>
           &ensp;oder&ensp;
           <a href="mailto:bc@bastet-project.de?subject=Anfrage zum Serviceangebot" title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>bc@bastet-project.de</a>
   </p>

   <p>
     Sie werden zukünftig über diese Seite alle Informationen
     zum Veranstaltungs-Management, -Planung &amp; -Durchführung
      sowie zu Räum- &amp; Transportdiensten
      und zur "Tierhilfe" finden. Der jeweilige Preis für eine Dienstleitung 
      ist von zu vielen Faktoren abhängig, als dass er pauschal angegeben werden kann.
     Ob Sie die komplette Organisation von diversen Feiern, Konzerten, Messen, Konferenzen, Wohnungsauflösungen, etc.
     oder "nur" Mithilfe wünschen/brauchen, sowie die jeweiligen Kosten der gewünschten Leistung, 
     kann bei einem unverbindlichen Gedankenaustausch erörtert werden.
   </p>
   
   <p>
     Aktuell sind die zeitlichen und vor allem örtlichen Möglichkeiten durch den Wiederaufbau des Gewerbes begrenzt.
     Somit können vorläufig auch keine Tiere mehr in Pflege oder zur Erziehung <u>aufgenommen</u> werden.
   </p>

{/**Hauselfentum beschreiben!
 * Bilder als Tooltips oder Childfenster (mit Scrollfunktion) anzeigen?!
 * 
*/}   

   <h2>Veranstaltungen</h2>
      komplett oder teilweise managen, planen und durchführen, etc.
      <p>
        Hierbei steht die Diskretion im Vordergrund, sodass die Teilnehmer bzw. Gäste die Veranstaltung ungestört genießen können.
      </p>
      <p></p>

    <h2>Haus-/Wohnungsräumung</h2>
       komplett oder teilweise managen, planen und durchführen, etc.
       <p>
         Hierbei steht die Sorgfalt im Vordergrund, unabhängig davon, ob Sie entrümpeln wollen oder umziehen.
       </p>
       <p></p>

    <h2>"Tierhilfe"</h2>
       Aquarien/Terrarien/Palentarien bauen/einrichten, Tiere (auch Vierbeiner) eingewöhnen oder "erziehen" etc.
       <p>
         Hierbei steht die Tierliebe im Vordergrund, damit sich Ihr (neuer) besonderer Lebensgefährte wohlfühlen kann und Sie die
         Zeit mit ihm genießen können. Natürlich können auch "plötzliche Wesensänderungen"
         unter die Lupe genommen und gegebenenfalls (um-)gelenkt werden.
       </p>
       <p></p>

  </Layout>
              )

              export default DienstleistungenKlartextPage
